import React, { useEffect } from "react";
import Emailscreen from "../screens/EmailScreen";
import "../components/EmailOverlay.css";

export default function EmailOverlay({
  onClose,
  selectedFilesToEmail,
  isOpen,
}) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable background scrolling
    } else {
      document.body.style.overflow = "auto"; // Re-enable background scrolling
    }

    return () => {
      document.body.style.overflow = "auto"; // Cleanup when the component unmounts
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="email-overlay">
      <div className="email-overlay-content">
        <span className="heading">Email</span>
        <button className="email-close-button" onClick={onClose}>
          X
        </button>
        <Emailscreen
          closeOverlay={onClose}
          selectedFilesToEmail={selectedFilesToEmail}
        />
      </div>
    </div>
  );
}
