import React, { useState } from "react";

import { createUserWithEmailAndPassword } from "firebase/auth";

import { auth, db } from "../components/firebase";
import { setDoc, doc } from "firebase/firestore";

import "./RegisterScreen.css";

import applogo1 from "../assets/logo5.jpg";

export default function RegisterScreen() {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  async function handelRegister(e) {
    e.preventDefault();

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      console.log(user);
      if (user) {
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          name: firstname,
          lastname: lastname,
        });
      }
      console.log("User register sucessfully");
      setMessage("Registered sucessfully!");
      setMessageType("success");
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        console.error(
          "The email address is already in use by another account."
        );
        setMessage(error.message);
        setMessageType("error");
      } else {
        console.error("Error registering user:", error);
      }
    }
  }

  function firstNameHandler(e) {
    setFirstName(e.target.value);
  }

  function lastNameHandler(e) {
    setLastName(e.target.value);
  }

  function emailHandler(e) {
    setEmail(e.target.value);
  }

  function passwordHandler(e) {
    setPassword(e.target.value);
  }

  return (
    <div className="register-container">
      <div className="register-box">
        <div className="logo-container">
          <img src={applogo1} alt="appLogo" className="register-logo" />
        </div>
        <form className="register-form" onSubmit={handelRegister}>
          <input
            type="text"
            value={firstname}
            placeholder="First name"
            onChange={firstNameHandler}
            className="register-input"
            required
          />
          <input
            type="text"
            value={lastname}
            placeholder="Last name"
            onChange={lastNameHandler}
            className="register-input"
            required
          />
          <input
            type="email"
            value={email}
            placeholder="Enter email"
            onChange={emailHandler}
            className="register-input"
            required
          />
          <input
            type="password"
            value={password}
            placeholder="Password"
            onChange={passwordHandler}
            className="register-input"
            required
          />
          <button type="submit" className="register-button">
            Sign Up
          </button>
        </form>
        {message && <p className={`message ${messageType}`}>{message}</p>}
        <p>
          Already registered <a href="/login"> Login</a>
        </p>
      </div>
    </div>
  );
}
