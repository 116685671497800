import { auth, db } from "../components/firebase";

import { useNavigate } from "react-router-dom";
// import "../screens/HomeScreen.css";
import "../screens/HomeScreen.css";

import React, { useEffect, useState } from "react";

import { doc, getDoc } from "firebase/firestore";
import { signOut } from "firebase/auth";
import { RingLoader } from "react-spinners";

import UploadOverlay from "../components/UploadOverlay";
import EmailOverlay from "../components/EmailOverlay";
import { message } from "antd";

import "../components/FilePreview/FilePreviewOverlay";

import Header1 from "../newComponent/Header";
import SideBar1 from "../newComponent/SideBar";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";

import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";

import FolderIcon from "@mui/icons-material/Folder";

import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import TrendingFlatOutlinedIcon from "@mui/icons-material/TrendingFlatOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

import FilePreviewOverlay from "../components/FilePreview/FilePreviewOverlay";
export const READ_API_URL = process.env.REACT_APP_READ_API_URL;

const createFolderTree = (data) => {
  const tree = {};
  const rootFolders = new Set();

  data.forEach((item) => {
    const parts = item.name.split("/");

    // Add the first part of the path (root folder) to rootFolders
    if (parts.length > 1) {
      rootFolders.add(parts[0]);
    }

    // Traverse through the folder tree structure
    let currentLevel = tree;
    for (let i = 0; i < parts.length - 1; i++) {
      const part = parts[i];

      // Initialize an object if the folder does not exist
      if (!currentLevel[part]) {
        currentLevel[part] = {}; // Only create the folder structure
      }

      // Move to the next level
      currentLevel = currentLevel[part];
    }
  });

  return { tree, rootFolders: Array.from(rootFolders) };
};

// New function to add paths to folders
// const addPathsToFolders = (folder, parentPath = "") => {
//   return Object.entries(folder).flatMap(([name, subfolder]) => {
//     const currentPath = `${parentPath}${name}/`;
//     const nestedFolders = addPathsToFolders(subfolder, currentPath);
//     return [{ name, isFolder: true, path: currentPath }, ...nestedFolders];
//   });
// };

const optionsIcons = {
  Preview: <VisibilityOutlinedIcon />,
  Download: <FileDownloadOutlinedIcon />,
  Email: <EmailOutlinedIcon />,
  Rename: <DriveFileRenameOutlineOutlinedIcon />,
  Copy: <ContentCopyOutlinedIcon />,
  Move: <TrendingFlatOutlinedIcon />,
  Delete: <DeleteOutlinedIcon />,
};

export default function HomeScreen() {
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedFolder, setSelectedFolder] = useState("Home"); // Track selected folder
  const [files, setFiles] = useState([]); // Files from the backend
  // const [currentFiles, setCurrentFiles] = useState([]);

  const [currentFolderPath, setCurrentFolderPath] = useState(""); // State to store current folder path

  const [folderTree, setFolderTree] = useState({});
  const [currentItems, setCurrentItems] = useState([]); // Items (files/folders) being displayed
  const [rootFolders, setRootFolders] = useState([]); // Root folders to display
  const [initialItems, setInitialItems] = useState([]); // New state for initial items

  const [checkedFiles, setCheckedFiles] = useState([]); // State to track checked files
  const [isAllSelected, setIsAllSelected] = useState(false); // State for the "Select All" checkbox

  const [messageApi, messageContextHolder] = message.useMessage();

  const [isUploadOverlayOpen, setIsUploadOverlayOpen] = useState(false);

  const [isEmailOverlayOpen, setIsEmailOverlayOpen] = useState(false);
  const [selectedFilesToEmail, setSelectedFilesToEmail] = useState([]);

  // const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [isFilePreviewOverlayOpen, setIsFilePreviewOverlayOpen] =
    useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFilePath, setSelectedFilePath] = useState();
  const [currentFileIndex, setCurrentFileIndex] = useState();

  const [multipleFileSelect, setMultipleFileSelect] = useState([]);

  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    options: [],
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserDetail(docSnap.data());
          } else {
            setError("User data not found.");
          }
        } else {
          setError("No user is signed in.");
        }
      } catch (err) {
        setError("Failed to fetch user data.");
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetail();
  }, []);

  // Fetch files and folders
  // Updated fetchFilesAndFolders to only display root files and folders on initial load
  const fetchFilesAndFolders = () => {
    fetch(`${READ_API_URL}/readAll`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // console.log("Fetched data:", data);

        const files = data.filter((item) => !item.name.includes("/")); // Only files not in folders
        const folders = data.filter((item) => item.name.includes("/"));

        const { tree, rootFolders } = createFolderTree(folders);

        setFolderTree(tree);
        setFiles(data);
        setRootFolders(rootFolders);

        // Set initial items to root-level files and folders
        const initialDisplayItems = [
          ...files.map((file) => ({
            name: file.name,
            isFolder: false,
            size: file.size,
            createdDate: file.createdDate,
            path: `/${file.name}`,
          })),
          ...rootFolders.map((folder) => ({
            name: folder,
            isFolder: true,
            path: `/${folder}`,
          })),
        ];

        setInitialItems(initialDisplayItems); // Save initial items
        setCurrentItems(initialDisplayItems); // Set current items to initial
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFilesAndFolders(); // Fetch files and folders on component mount
  }, []);

  // Add this useEffect to handle the checkbox state based on checkedFiles

  // Sync isAllSelected state with checked files
  useEffect(() => {
    const totalFiles = currentItems.filter((item) => !item.isFolder).length;
    setIsAllSelected(checkedFiles.length === totalFiles && totalFiles > 0);
  }, [checkedFiles, currentItems]);

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" className="ring" />
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  // Folder click handling

  const extractFileName = (filePath) => {
    return filePath.split("/").pop();
  };

  // Folder click
  // Handle "Home" click

  const handleFolderClick = (folderPath) => {
    if (folderPath === "Home") {
      fetchFilesAndFolders(); // Reset to the root folder
      setSelectedFolder("Home");
      setCurrentFolderPath("");
      console.log(`Home is clicked : ${currentFolderPath}`);
      // Reset to initial files and root folders
      setCurrentItems(initialItems);
      return; // Stop further execution
    }

    // Update selected folder and the current folder path
    setSelectedFolder(folderPath);
    // setCurrentFolderPath(folderPath ? `${folderPath}/` : "");

    const newFolderPath = folderPath ? `${folderPath}/` : "";
    setCurrentFolderPath(newFolderPath);

    // Filter files directly inside the selected folder
    const folderFiles = files.filter((file) => {
      return (
        file.name.startsWith(`${folderPath}/`) &&
        file.name.substring(folderPath.length + 1).split("/").length === 1
      );
    });

    // Navigate through the folder tree structure
    const folderParts = folderPath.split("/");
    let currentFolderTree = folderTree;

    folderParts.forEach((part) => {
      if (currentFolderTree[part]) {
        currentFolderTree = currentFolderTree[part];
      }
    });

    // Subfolders under the current folder
    const subfolders = Object.keys(currentFolderTree);
    const folderFolders = subfolders.map((subfolder) => ({
      name: `${folderPath}/${subfolder}`,
      isFolder: true,
      path: `${folderPath}/${subfolder}`,
    }));

    // Combine files and subfolders for the current folder
    const newCurrentItems = [
      ...folderFiles.map((file) => ({
        name: file.name,
        isFolder: false,
        path: file.name,
        createdDate: file.createdDate,
        size: file.size,
      })),
      ...folderFolders,
    ];

    setCurrentItems(newCurrentItems); // Update the current items displayed
  };

  // Handle Select All
  const handleSelectAll = () => {
    if (isAllSelected) {
      setCheckedFiles([]); // Uncheck all files
    } else {
      const allFileNames = currentItems
        .filter((item) => !item.isFolder)
        .map((file) => file.name);
      setCheckedFiles(allFileNames); // Select all files
    }
    setIsAllSelected(!isAllSelected); // Toggle Select All state
  };

  // Handle individual file checkbox change
  const handleCheckboxChange = (fileName) => {
    if (checkedFiles.includes(fileName)) {
      setCheckedFiles(checkedFiles.filter((name) => name !== fileName));
    } else {
      setCheckedFiles([...checkedFiles, fileName]);
    }

    const totalFiles = currentItems.filter((item) => !item.isFolder).length;
    setIsAllSelected(
      checkedFiles.length + (checkedFiles.includes(fileName) ? -1 : 1) ===
        totalFiles
    );
  };

  const hardcodedReceivedDate = "2024-10-01";

  const handleUploadButtonClick = () => {
    setIsUploadOverlayOpen(true);
  };

  const handleCloseOverlay = () => {
    setIsUploadOverlayOpen(false);
  };

  const handleFilePreviewClose = () => {
    setIsFilePreviewOverlayOpen(false);
  };

  const handleFileClick = (fileName, filePath, index, fileList) => {
    console.log(fileName);
    console.log(filePath);
    setSelectedFile(fileName);
    setSelectedFilePath(filePath);
    setCurrentFileIndex(index);
    setCurrentItems(fileList);
    setIsFilePreviewOverlayOpen(true);
  };

  // Handle email button click
  const handelEmailButton = () => {
    const anyFilesSelected = checkedFiles.length > 0;

    if (!anyFilesSelected) {
      messageApi.open({
        type: "error",
        content: "Please Select a File To Proceed.",
        duration: 3,
      });
    } else {
      const filesToSend = currentItems.filter((item) =>
        checkedFiles.includes(item.name)
      );
      setSelectedFilesToEmail(filesToSend);

      console.log(
        `FILES TO SEND EMAIL FOR CHECK: ${filesToSend.map((items) => items)}`
      );
      setIsEmailOverlayOpen(true);
    }
  };

  // Close the email overlay after success
  const closeEmailOverLay = (result) => {
    setIsEmailOverlayOpen(false);
    if (result === true) {
      messageApi.open({
        type: "success",
        content: "Email sent successfully!",
        duration: 3,
      });
      setCheckedFiles([]);
      setIsAllSelected(false);
    }
  };

  /* HANDEL SINGLE AND MULTIPLE FILE CLICK AND CONTEXT MENU */

  const handelSingleFileClick = (event, file) => {
    // MultiSelect with ctrl/meta key
    if (event.ctrlKey || event.metaKey) {
      handelMultipleFileClick(file);
    } else {
      // select single file (deselect others)
      const selectedFile = {
        name: file.name,
        size: file.size,
        path: file.path,
      };

      if (multipleFileSelect.some((item) => item.name === selectedFile.name)) {
        // Deselect if already selected
        const filteredFile = multipleFileSelect.filter(
          (item) => item.name !== selectedFile.name
        );

        setMultipleFileSelect(filteredFile);
      } else {
        setMultipleFileSelect([selectedFile]);
      }
    }
    //  Hide context menu if visible
    setContextMenu({ visible: false });
  };

  const handelMultipleFileClick = (file) => {
    const selectedFile = { name: file.name, size: file.size, path: file.path };
    setMultipleFileSelect((prev) => {
      if (prev.some((item) => item.name === selectedFile.name)) {
        // If Already selected remove it
        return prev.filter((item) => item.name !== selectedFile.name);
      } else {
        return [...prev, selectedFile];
      }
    });
  };

  const handelRightClick = (event, file) => {
    //  prevent the default context menu
    event.preventDefault();

    // Check if the clicked file is already selected
    const isFileSelected = multipleFileSelect.some(
      (item) => item.name === file.name
    );

    // If no file is selected, do not show the context menu
    if (!isFileSelected && !event.ctrlKey) {
      // exit early if no file is selected and no ctrl/meta key is presses
      return;
    }

    //  HANDEL SELECTION LOGIC

    if (!event.ctrlKey && !isFileSelected) {
      setMultipleFileSelect([{ name: file.name, size: file.size }]);
    } else if (event.ctrlKey && !isFileSelected) {
      handelMultipleFileClick(file);
    }

    let options;

    if (multipleFileSelect.length === 1 && isFileSelected) {
      options = [
        "Preview",
        "Download",
        "Email",
        "Rename",
        "Copy",
        "Move",
        "Delete",
      ];
    } else if (
      multipleFileSelect.length > 1 ||
      (multipleFileSelect.length === 0 && !isFileSelected)
    ) {
      options = ["Download", "Email", "Copy", "Move", "Delete"];
    }

    setContextMenu({
      visible: true,
      x: event.clientX,
      y: event.clientY,
      options,
    });
  };

  console.log(
    `MULTIPLE FILE SELECT: ${multipleFileSelect.map((file) => file.name)}`
  );

  const handleContextMenuClose = () => {
    setContextMenu({ visible: false });
  };

  const extractName = (filePath) => {
    return filePath.split("/").pop();
  };

  const handelContextMenuOptionClick = (option, selectedFiles) => {
    handleContextMenuClose();

    const getFileNames = (files) => {
      return files.map((file) => extractFileName(file.name));
    };

    switch (option) {
      case "Preview":
        // console.log(selectedFiles.map((item) => item.name));
        console.log(getFileNames(selectedFiles));

        if (selectedFiles.length === 1) {
          const fileToPreview = selectedFiles[0];
          const index = currentItems.findIndex(
            (item) => item.name === fileToPreview.name
          );

          handleFileClick(
            fileToPreview.name,
            fileToPreview.path,
            index,
            currentItems
          );
        }

        setMultipleFileSelect([]);

        break;

      case "Download":
        console.log(selectedFiles.map((item) => item.name));

        setMultipleFileSelect([]);
        break;

      case "Email":
        console.log(selectedFiles.map((item) => item.path));

        setSelectedFilesToEmail(selectedFiles);
        console.log(`selected files for email: ${selectedFiles}`);
        setIsEmailOverlayOpen(true);

        setMultipleFileSelect([]);
        break;

      case "Rename":
        console.log(selectedFiles.map((item) => item.name));

        setMultipleFileSelect([]);
        break;

      case "Copy":
        console.log(selectedFiles.map((item) => item.name));

        setMultipleFileSelect([]);
        break;

      case "Move":
        console.log(selectedFiles.map((item) => item.name));

        setMultipleFileSelect([]);
        break;

      case "Delete":
        console.log(selectedFiles.map((item) => item.name));

        setMultipleFileSelect([]);
        break;

      default:
        break;
    }
  };

  console.log(
    `VERIFYING THE PATH FOR SELECTED FILES: ${multipleFileSelect.map(
      (item) => item.path
    )}`
  );

  return (
    <>
      {messageContextHolder}
      {isUploadOverlayOpen && (
        <UploadOverlay
          isOpen={isUploadOverlayOpen}
          onClose={handleCloseOverlay}
          folderPath={currentFolderPath} //Pass the selected folderpath
        />
      )}

      {isEmailOverlayOpen && (
        <EmailOverlay
          onClose={closeEmailOverLay}
          selectedFilesToEmail={selectedFilesToEmail}
          isOpen={isEmailOverlayOpen}
        />
      )}

      {userDetail && (
        <Header1
          email={userDetail.email}
          onLogout={handleLogout}
          name={userDetail.name}
          photoURL={userDetail.profilePicture}
        />
      )}

      <div className="home-body1">
        <SideBar1
          folderTree={folderTree}
          onFolderClick={handleFolderClick}
          selectedFolder={selectedFolder}
          onUploadButtonClick={handleUploadButtonClick}
        />

        <div className="container2">
          <div className="container2-options">
            <div
              className="container2-option selectall"
              onClick={handleSelectAll}
            >
              <DoneOutlinedIcon />
              <span>Select All</span>
            </div>
            {/* <div
              className="container2-option email"
              onClick={handelEmailButton}
            >
              <EmailOutlinedIcon />
              <span>Email</span>
            </div>  */}
            <div className="container2-option download">
              <DownloadOutlinedIcon />
              <span>Download</span>
            </div>
            <div className="container2-option rename">
              <DriveFileRenameOutlineOutlinedIcon />
              <span>Rename</span>
            </div>
          </div>

          <div className="container2-body">
            <div className="details-Row title">
              <input
                type="checkbox"
                checked={isAllSelected}
                onChange={handleSelectAll}
              />
              <p>
                <b>
                  Name <ArrowDownwardOutlinedIcon />
                </b>
              </p>
              <p className="cr-date">
                <b>Created Date</b>
              </p>
              <p className="size">
                <b>Size</b>
              </p>
              <p className="re-date">
                <b>ReceivedDate</b>
              </p>
            </div>

            {currentItems.map((item, index) => (
              <div
                key={index}
                // className="details-Row"
                className={`details-Row ${
                  multipleFileSelect.some((file) => file.name === item.name)
                    ? "selected-file"
                    : ""
                }`}
                onClick={(event) => handelSingleFileClick(event, item)}
                onContextMenu={(event) => handelRightClick(event, item)}

                // style={{
                //   backgroundColor: multipleFileSelect.some(
                //     (file) => file.name === item.name
                //   )
                //     ? "#c2e7ff"
                //     : "transparent",
                // }}
              >
                <input
                  type="checkbox"
                  checked={checkedFiles.includes(item.name)}
                  onChange={() => handleCheckboxChange(item.name)}
                />

                {item.isFolder ? (
                  <FolderIcon
                    className="folder-icon"
                    style={{
                      // marginRight: "8px",
                      width: "17px",
                      // color: "gray",
                      // background: "white",
                    }}
                  />
                ) : (
                  <InsertDriveFileIcon
                    className="drive-file-icon"
                    style={{
                      // marginRight: "8px",
                      width: "15px",
                      //   color: "#0f67b1",
                      //   background: "white",
                    }}
                  />
                )}
                <p
                // style={{ cursor: "pointer" }}
                // onClick={() =>
                //   handleFileClick(item.name, item.path, index, currentItems)
                // }  Function for preview
                >
                  {extractFileName(item.name)}
                </p>
                <p>{item.isFolder ? "-" : item.createdDate}</p>
                <p>{item.isFolder ? "-" : `${item.size}KB`}</p>
                <p>{item.isFolder ? "-" : hardcodedReceivedDate}</p>
              </div>
            ))}

            {isFilePreviewOverlayOpen && (
              <FilePreviewOverlay
                fileName={selectedFile}
                filePath={selectedFilePath}
                fileIndex={currentFileIndex}
                allFiles={currentItems}
                onClose={handleFilePreviewClose}
              />
            )}

            {contextMenu.visible && (
              <ul
                className="context-menu"
                style={{
                  top: contextMenu.y,
                  left: contextMenu.x,
                }}
              >
                {contextMenu.options.map((option, idx) => (
                  <React.Fragment key={idx}>
                    <li
                      onClick={() =>
                        handelContextMenuOptionClick(option, multipleFileSelect)
                      }
                      style={{
                        marginTop:
                          option === "Download" ||
                          option === "Copy" ||
                          option === "Delete"
                            ? "10px"
                            : "0",
                      }}
                    >
                      <span style={{ marginRight: "8px" }}>
                        {optionsIcons[option]}
                      </span>
                      {option}
                    </li>
                    {option === "Preview" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                    {option === "Rename" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                    {option === "Move" && (
                      <hr
                        style={{
                          border: "none",
                          borderBottom: "0.5px solid rgb(211, 211, 211)",
                          margin: "5px 0",
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </ul>

              // <ul
              //   className="context-menu"
              //   style={{
              //     top: contextMenu.y,
              //     left: contextMenu.x,
              //   }}
              // >
              //   {contextMenu.options.map((option, idx) => (
              //     <li
              //       key={idx}
              //       onClick={() =>
              //         handelContextMenuOptionClick(option, multipleFileSelect)
              //       }
              //     >
              //       <span style={{ marginRight: "8px" }}>
              //         {optionsIcons[option]}
              //       </span>
              //       {option}
              //     </li>
              //   ))}
              // </ul>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

// --------------------------------------------

// const createFolderTree = (data) => {
//   const tree = {};
//   const rootFolders = new Set();

//   data.forEach((item) => {
//     const parts = item.name.split("/");

//     // Add the first part of the path (root folder) to rootFolders
//     if (parts.length > 1) {
//       rootFolders.add(parts[0]);
//     }

//     // Traverse through the folder tree structure
//     let currentLevel = tree;
//     for (let i = 0; i < parts.length - 1; i++) {
//       const part = parts[i];

//       // Initialize an object if the folder does not exist
//       if (!currentLevel[part]) {
//         currentLevel[part] = {}; // Only create the folder structure without hasSubFolders
//       }

//       // Move to the next level
//       currentLevel = currentLevel[part];
//     }
//   });

//   return { tree, rootFolders: Array.from(rootFolders) };
// };

// export default function HomeScreen() {
//   const [userDetail, setUserDetail] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const [selectedFolder, setSelectedFolder] = useState("Home"); // Track selected folder
//   const [files, setFiles] = useState([]); // Files from the backend
//   // const [currentFiles, setCurrentFiles] = useState([]);

//   const [currentFolderPath, setCurrentFolderPath] = useState(null); // State to store current folder path

//   const [folderTree, setFolderTree] = useState({});
//   const [currentItems, setCurrentItems] = useState([]); // Items (files/folders) being displayed
//   const [rootFolders, setRootFolders] = useState([]); // Root folders to display
//   const [initialItems, setInitialItems] = useState([]); // New state for initial items

//   const [checkedFiles, setCheckedFiles] = useState([]); // State to track checked files
//   const [isAllSelected, setIsAllSelected] = useState(false); // State for the "Select All" checkbox

//   const [messageApi, messageContextHolder] = message.useMessage();

//   const [isUploadOverlayOpen, setIsUploadOverlayOpen] = useState(false);

//   // const [isDrawerOpen, setDrawerOpen] = useState(false);

//   const [isFilePreviewOverlayOpen, setIsFilePreviewOverlayOpen] =
//     useState(false);

//   const [selectedFile, setSelectedFile] = useState(null);
//   const [selectedFilePath, setSelectedFilePath] = useState();
//   const [currentFileIndex, setCurrentFileIndex] = useState();

//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchUserDetail = async () => {
//       try {
//         const user = auth.currentUser;
//         if (user) {
//           const docRef = doc(db, "Users", user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             setUserDetail(docSnap.data());
//           } else {
//             setError("User data not found.");
//           }
//         } else {
//           setError("No user is signed in.");
//         }
//       } catch (err) {
//         setError("Failed to fetch user data.");
//         console.error("Error fetching user data:", err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserDetail();
//   }, []);

//   // Fetch files and folders

//   const fetchFilesAndFolders = () => {
//     fetch(`${READ_API_URL}/readAll`)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         console.log("Fetched data:", data);
//         const folders = data.filter((item) => item.name.includes("/"));
//         const files = data.filter((item) => !item.name.includes("/"));
//         const { tree, rootFolders } = createFolderTree(folders);

//         setFolderTree(tree);
//         setFiles(data);
//         setRootFolders(rootFolders);

//         // Set initial items to state and also current items
//         // const initialDisplayItems = [
//         //   ...files,
//         //   ...rootFolders.map((folder) => ({ name: folder, isFolder: true })),
//         // ];
//         // Update initialDisplayItems to include paths
//         const initialDisplayItems = [
//           ...files.map((file) => ({
//             name: file.name,
//             isFolder: false,
//             path: file.path,
//           })), // Add path for files
//           ...rootFolders.map((folder) => ({
//             name: folder,
//             isFolder: true,
//             path: folder,
//           })), // Add path for folders
//         ];
//         setInitialItems(initialDisplayItems); // Save initial items state
//         setCurrentItems(initialDisplayItems); // Set initial view
//         setLoading(false);
//       })
//       .catch((error) => {
//         setError(error);
//         setLoading(false);
//       });
//   };
//   useEffect(() => {
//     fetchFilesAndFolders(); // Fetch files and folders on component mount
//   }, []);

//   // Add this useEffect to handle the checkbox state based on checkedFiles
//   useEffect(() => {
//     const totalFiles = currentItems.filter((item) => !item.isFolder).length;
//     if (checkedFiles.length === totalFiles && totalFiles > 0) {
//       setIsAllSelected(true);
//     } else {
//       setIsAllSelected(false);
//     }
//   }, [checkedFiles, currentItems]);

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         <RingLoader color="#3498db" className="ring" />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       navigate("/login");
//     } catch (error) {
//       console.error("Error signing out: ", error);
//     }
//   };

//   // Folder click handling

//   const extractFileName = (filePath) => {
//     return filePath.split("/").pop();
//   };

//   // Folder click handling
//   const handleFolderClick = (folderPath) => {
//     if (folderPath === "Home") {
//       // Check if clicked on "Home"
//       console.log("Home");
//       fetchFilesAndFolders(); // Fetch latest files and folders
//       setSelectedFolder("Home"); // Indicate Home is selected
//       setCurrentFolderPath(null); // Clear folder path
//       setCurrentItems(initialItems); // Reset current items to initial items
//       return; // Early return since we don't need further logic
//     }
//     // If another folder is clicked, proceed with existing logic
//     setSelectedFolder(folderPath);
//     setCurrentFolderPath(folderPath);

//     // Get files directly inside the selected folder
//     // const folderFiles = files.filter((file) => {
//     //   return (
//     //     file.name.startsWith(`${folderPath}/`) &&
//     //     !file.name.substring(folderPath.length + 1).includes("/")
//     //   );
//     // });
//     const folderFiles = files
//       .filter((file) => {
//         return (
//           file.name.startsWith(`${folderPath}/`) &&
//           !file.name.substring(folderPath.length + 1).includes("/")
//         );
//       })
//       .map((file) => ({ name: file.name, isFolder: false, path: file.path })); // Ensure path is included

//     // Extract subfolders inside the clicked folder
//     const folderParts = folderPath.split("/");
//     let currentFolderTree = folderTree;

//     // Navigate to the specific folder in the tree
//     folderParts.forEach((part) => {
//       if (currentFolderTree[part]) {
//         currentFolderTree = currentFolderTree[part];
//       }
//     });

//     // Get subfolder names
//     const subfolders = Object.keys(currentFolderTree).filter(
//       (folder) => folder !== ""
//     );

//     const folderFolders = subfolders.map((subfolder) => ({
//       name: `${folderPath}/${subfolder}`,
//       isFolder: true,
//       path: `${folderPath}/${subfolder}`, // Add path for folders
//     }));

//     // Set items to display (files and subfolders)
//     setCurrentItems([...folderFiles, ...folderFolders]);
//   };

//   console.log("Current Items: ", currentItems);

//   const handleSelectAll = () => {
//     if (isAllSelected) {
//       setCheckedFiles([]); // Uncheck all files
//     } else {
//       const allFileNames = currentItems
//         .filter((item) => !item.isFolder) // Select only files
//         .map((file) => file.name);
//       setCheckedFiles(allFileNames); // Select all files
//     }
//     setIsAllSelected(!isAllSelected); // Toggle Select All state
//   };

//   const handleCheckboxChange = (fileName) => {
//     if (checkedFiles.includes(fileName)) {
//       setCheckedFiles(checkedFiles.filter((name) => name !== fileName));
//     } else {
//       setCheckedFiles([...checkedFiles, fileName]);
//     }

//     // Update the isAllSelected state based on checkedFiles length
//     const totalFiles = currentItems.filter((item) => !item.isFolder).length;
//     setIsAllSelected(
//       checkedFiles.length + (checkedFiles.includes(fileName) ? -1 : 1) ===
//         totalFiles
//     );
//   };

//   // console.log(currentFiles);

//   const hardcodedReceivedDate = "2024-10-01";

//   const handleUploadButtonClick = () => {
//     setIsUploadOverlayOpen(true);
//   };

//   const handleCloseOverlay = () => {
//     setIsUploadOverlayOpen(false);
//   };

//   const handleFilePreviewClose = () => {
//     setIsFilePreviewOverlayOpen(false);
//   };

//   const handleFileClick = (fileName, filePath, index, fileList) => {
//     console.log(fileName);
//     console.log(filePath);
//     setSelectedFile(fileName);
//     setSelectedFilePath(filePath);
//     setCurrentFileIndex(index);
//     setCurrentItems(fileList);
//     setIsFilePreviewOverlayOpen(true);
//   };

//   return (
//     <>
//       {messageContextHolder}
//       {isUploadOverlayOpen && (
//         <UploadOverlay
//           isOpen={isUploadOverlayOpen}
//           onClose={handleCloseOverlay}
//         />
//       )}

//       {userDetail && (
//         <Header1
//           email={userDetail.email}
//           onLogout={handleLogout}
//           name={userDetail.name}
//           photoURL={userDetail.profilePicture}
//         />
//       )}

//       <div className="home-body1">
//         <SideBar1
//           folderTree={folderTree}
//           onFolderClick={handleFolderClick}
//           selectedFolder={selectedFolder}
//           onUploadButtonClick={handleUploadButtonClick}
//         />

//         <div className="container2">
//           <div className="container2-options">
//             <div
//               className="container2-option selectall"
//               onClick={handleSelectAll}
//             >
//               <DoneOutlinedIcon />
//               <span>Select All</span>
//             </div>
//             <div className="container2-option email">
//               <EmailOutlinedIcon />
//               <span>Email</span>
//             </div>
//             <div className="container2-option download">
//               <DownloadOutlinedIcon />
//               <span>Download</span>
//             </div>
//             <div className="container2-option rename">
//               <DriveFileRenameOutlineOutlinedIcon />
//               <span>Rename</span>
//             </div>
//           </div>

//           <div className="container2-body">
//             <div className="details-Row title">
//               <input
//                 type="checkbox"
//                 checked={isAllSelected}
//                 onChange={handleSelectAll}
//               />
//               <p>
//                 <b>
//                   Name <ArrowDownwardOutlinedIcon />
//                 </b>
//               </p>
//               <p className="cr-date">
//                 <b>Created Date</b>
//               </p>
//               <p className="size">
//                 <b>Size</b>
//               </p>
//               <p className="re-date">
//                 <b>ReceivedDate</b>
//               </p>
//             </div>

//             {currentItems.map((item, index) => (
//               <div key={index} className="details-Row">
//                 <input
//                   type="checkbox"
//                   checked={checkedFiles.includes(item.name)}
//                   onChange={() => handleCheckboxChange(item.name)}
//                 />

//                 {/* Render the appropriate icon based on whether the item is a folder or a file */}
//                 {item.isFolder ? (
//                   <FolderIcon
//                     style={{
//                       marginRight: "8px",
//                       width: "17px",
//                       color: "gray",
//                       background: "white",
//                     }}
//                   />
//                 ) : (
//                   <InsertDriveFileIcon
//                     style={{
//                       marginRight: "8px",
//                       width: "15px",
//                       color: "#0f67b1",
//                       background: "white",
//                     }}
//                   />
//                 )}
//                 <p
//                   onClick={() =>
//                     handleFileClick(item.name, item.path, index, currentItems)
//                   }
//                 >
//                   {extractFileName(item.name)}
//                 </p>
//                 <p>{item.isFolder ? "-" : item.createdDate}</p>
//                 <p>{item.isFolder ? "-" : `${item.size}KB`}</p>
//                 <p>{item.isFolder ? "-" : hardcodedReceivedDate}</p>
//               </div>
//             ))}

//             {isFilePreviewOverlayOpen && (
//               <FilePreviewOverlay
//                 fileName={selectedFile}
//                 filePath={selectedFilePath}
//                 fileIndex={currentFileIndex}
//                 allFiles={currentItems}
//                 onClose={handleFilePreviewClose}
//               />
//             )}
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// ------------------------------------------------------

// import { auth, db } from "../components/firebase";

// import { useNavigate } from "react-router-dom";
// import "../screens/HomeScreen.css";
// import Header from "../components/Header";
// import { useEffect, useState } from "react";
// import Sidebar from "../components/SideBar";
// import { doc, getDoc } from "firebase/firestore";
// import { signOut } from "firebase/auth";
// import { RingLoader } from "react-spinners";
// import FolderTree from "../components/FolderTree";
// import FilePreviewOverlay from "../components/FilePreview/FilePreviewOverlay";
// import UploadOverlay from "../components/UploadOverlay";
// import EmailOverlay from "../components/EmailOverlay";
// import { message } from "antd";

// export const READ_API_URL = process.env.REACT_APP_READ_API_URL;

// export default function HomeScreen() {
//   const [userDetail, setUserDetail] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const [isFilePreviewOverlayOpen, setIsFilePreviewOverlayOpen] =
//     useState(false);
//   const [folderTree, setFolderTree] = useState(null); // For folder structure
//   const [fileList, setFileList] = useState([]); // For table

//   const [selectedFile, setSelectedFile] = useState(null);
//   const [selectedFilePath, setSelectedFilePath] = useState();
//   const [currentFileIndex, setCurrentFileIndex] = useState();

//   const [selectedFiles, setSelectedFiles] = useState({}); // chekcbox
//   const [selectAll, setSelectAll] = useState(false);
//   const [errorMessage, setErrorMessage] = useState("");

//   const [dropdownValue, setDropdownValue] = useState("Select");
//   const [triggerRefresh, setTriggerRefresh] = useState(false);

//   const [isUploadOverlayOpen, setIsUploadOverlayOpen] = useState(false);
//   const [isEmailOverlayOpen, setIsEmailOverlayOpen] = useState(false);
//   const [selectedFilesToEmail, setSelectedFilesToEmail] = useState([]);

//   // const [singleFileSelect, setSingleFileSelect] = useState("");
//   const [multipleFileSelect, setMultipleFileSelect] = useState([]);
//   const [contextMenu, setContextMenu] = useState({
//     visible: false,
//     x: 0,
//     y: 0,
//     options: [],
//   });

//   const navigate = useNavigate();
//   const [messageApi, messageContextHolder] = message.useMessage();

//   console.log(fileList);

//   useEffect(() => {
//     const fetchUserDetail = async () => {
//       try {
//         const user = auth.currentUser;
//         if (user) {
//           const docRef = doc(db, "Users", user.uid);
//           const docSnap = await getDoc(docRef);

//           if (docSnap.exists()) {
//             setUserDetail(docSnap.data());
//           } else {
//             setError("User data not found.");
//           }
//         } else {
//           setError("No user is signed in.");
//         }
//       } catch (err) {
//         setError("Failed to fetch user data.");
//         console.error("Error fetching user data:", err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUserDetail();
//   }, []);

//   //  FUNCTION TO SEPERATE FILES AND FOLDERS

//   const separateFilesAndFolders = (data) => {
//     const folderTree = {
//       name: "Home",
//       type: "folder",
//       contents: [],
//     };

//     const fileList = [];

//     data.forEach((item) => {
//       // Assuming the received data is available in item.receivedDate
//       if (item.name.includes("/")) {
//         const parts = item.name.split("/");
//         let currentFolder = folderTree;

//         parts.forEach((part, index) => {
//           if (index === parts.length - 1) {
//             // It's a file
//             currentFolder.contents.push({
//               name: part,
//               type: "file",
//               path: `/${item.name}`,
//               createdDate: item.createdDate,
//               receivedDate: item.receivedDate, // Add received date here
//               size: item.size,
//             });
//           } else {
//             // It's a folder
//             let folder = currentFolder.contents.find(
//               (content) => content.name === part && content.type === "folder"
//             );

//             if (!folder) {
//               folder = {
//                 name: part,
//                 type: "folder",
//                 contents: [],
//               };
//               currentFolder.contents.push(folder);
//             }

//             currentFolder = folder;
//           }
//         });
//       } else {
//         // Files without a folder go directly into the file list
//         fileList.push({
//           name: item.name,
//           path: `/${item.name}`,
//           createdDate: item.createdDate,
//           receivedDate: item.receivedDate, // Add received date here
//           size: item.size,
//         });
//       }
//     });
//     return { folderTree, fileList };
//   };

//   // Fetch data and apply separation logic
//   useEffect(() => {
//     // fetch("https://159.65.181.101:5002/readAll")
//     fetch(`${READ_API_URL}/readAll`)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         console.log("Data fetched from backend: ", data);
//         const { folderTree, fileList } = separateFilesAndFolders(data);
//         // console.log(data);
//         setFolderTree(folderTree); // For Folder Tree
//         // console.log("Folder Tree:");
//         // console.log(folderTree);
//         setFileList(fileList); // For File Table
//         setLoading(false);

//         // console.log(fileList);
//       })
//       .catch((error) => {
//         setError(error);
//         setLoading(false);
//       });
//   }, [triggerRefresh]);

//   if (loading) {
//     return (
//       <div className="spinner-container">
//         <RingLoader color="#3498db" />
//       </div>
//     );
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   const handleLogout = async () => {
//     try {
//       await signOut(auth);
//       navigate("/login");
//     } catch (error) {
//       console.error("Error signing out: ", error);
//     }
//   };

//   const handleFilePreviewClose = () => {
//     setIsFilePreviewOverlayOpen(false);
//   };

//   const handleFileClick = (fileName, filePath, index, fileList) => {
//     setSelectedFile(fileName);
//     setSelectedFilePath(filePath);
//     setCurrentFileIndex(index);
//     setFileList(fileList);
//     setIsFilePreviewOverlayOpen(true);
//   };

//   const handleFolderSelect = (folderName) => {
//     console.log("Selected folder:", folderName); // Debug: Log the selected folder

//     if (folderName === "Home") {
//       setTriggerRefresh((prev) => !prev); // Trigger the useEffect to refresh data
//     } else {
//       // Handle folder selection for non-Home folders
//       const getFilesFromFolder = (folder, folderName) => {
//         if (folder.name === folderName) {
//           return folder.contents.filter((item) => item.type === "file");
//         }
//         for (const content of folder.contents) {
//           if (content.type === "folder") {
//             const files = getFilesFromFolder(content, folderName);
//             if (files.length) return files;
//           }
//         }
//         return [];
//       };

//       const filesInFolder = getFilesFromFolder(folderTree, folderName);
//       setFileList(filesInFolder); // Set the files from the selected folder
//     }
//   };

//   const handleFileSelectFromTree = (file) => {
//     // Use the file object directly
//     setFileList([file]); // Display the selected file in the table
//   };

//   const handleFileSelectChange = (fileName) => {
//     setSelectedFiles((prev) => {
//       const newSelectedFiles = {
//         ...prev,
//         [fileName]: !prev[fileName],
//       };

//       // Check if any file is unchecked to update selectAll state
//       const anyUnchecked = fileList.some(
//         (file) => !newSelectedFiles[file.name]
//       );
//       setSelectAll(!anyUnchecked); // If any file is unchecked, set selectAll to false

//       return newSelectedFiles;
//     });
//   };

//   const handleSelectAll = () => {
//     const newSelectAllState = !selectAll;
//     setSelectAll(newSelectAllState);

//     // Update selectedFiles to select/deselect all
//     const updatedSelection = {};
//     fileList.forEach((file) => {
//       updatedSelection[file.name] = newSelectAllState;
//     });
//     setSelectedFiles(updatedSelection);
//   };

//   const handleSelectAllButtonClick = () => {
//     handleSelectAll();
//   };

//   const handleDropdownChange = (event) => {
//     const selectedOption = event.target.value;
//     setDropdownValue(selectedOption); // Update the dropdown state

//     if (selectedOption === "Email") {
//       const anyFilesSelected = Object.values(selectedFiles).some(Boolean);

//       if (!anyFilesSelected) {
//         setErrorMessage("Please Select a File To Proceed.");
//         setDropdownValue("Select");

//         setTimeout(() => {
//           setErrorMessage(null);
//         }, 3000);
//       } else {
//         setErrorMessage(""); // Clear any previous error message
//         // Prepare the selected files to send to the EmailScreen
//         const filesToSend = fileList.filter((file) => selectedFiles[file.name]);
//         // navigate("/email", { state: { selectedFiles: filesToSend } });
//         setSelectedFilesToEmail(filesToSend);
//         setIsEmailOverlayOpen(true);
//         setDropdownValue("Select");
//         setSelectedFiles([]);
//         setSelectAll("");
//         document.body.classList.add("email-modal-open");
//       }
//     } else if (selectedOption === "Fax") {
//       // Handle Fax navigation if needed
//     } else if (selectedOption === "Download") {
//       // Handle Download navigation if needed
//     } else if (selectedOption === "Rename") {
//       // Handle Rename navigation if needed
//     }
//   };

//   const handelUploadClick = () => {
//     setIsUploadOverlayOpen(true);
//   };

//   const closeUploadOverLay = () => {
//     setIsUploadOverlayOpen(false);
//   };
//   const closeEmailOverLay = (result) => {
//     document.body.classList.remove("email-modal-open");
//     setIsEmailOverlayOpen(false);
//     if (result == true) {
//       messageApi.open({
//         type: "success",
//         content: "Email sent successfully!",
//         duration: 3,
//       });
//     }
//   };

//   // Function to handle single-click (left-click) on a file name
//   function handleSingleFileClick(event, file) {
//     if (event.ctrlKey || event.metaKey) {
//       // Multiselect with Ctrl/Meta key
//       handleMultiSelect(file);
//     } else {
//       // Select a single file (and deselect others)
//       const selectedFile = { name: file.name, size: file.size };
//       if (multipleFileSelect.some((item) => item.name === selectedFile.name)) {
//         // Deselect if already selected
//         setMultipleFileSelect(
//           multipleFileSelect.filter((item) => item.name !== selectedFile.name)
//         );
//       } else {
//         setMultipleFileSelect([selectedFile]);
//       }
//     }
//     setContextMenu({ visible: false }); // Hide context menu if visible
//   }

//   // Function to handle multiple selection
//   const handleMultiSelect = (file) => {
//     const selectedFile = { name: file.name, size: file.size };
//     setMultipleFileSelect((prev) => {
//       if (prev.some((item) => item.name === selectedFile.name)) {
//         // If already selected, remove it
//         return prev.filter((item) => item.name !== selectedFile.name);
//       } else {
//         return [...prev, selectedFile];
//       }
//     });
//   };

//   // Function to handel right click
//   const handleRightClick = (event, file) => {
//     event.preventDefault(); // Prevent the default context menu

//     // Check if the clicked file is already selected
//     const isFileSelected = multipleFileSelect.some(
//       (item) => item.name === file.name
//     );

//     // If no file is selected, do not show the context menu
//     if (!isFileSelected && !event.ctrlKey) {
//       return; // Exit early if no file is selected and no Ctrl/Meta key is pressed
//     }

//     // Handle selection logic
//     if (!event.ctrlKey && !isFileSelected) {
//       // If no Ctrl/Meta key and the file is not selected, select only this file
//       setMultipleFileSelect([{ name: file.name, size: file.size }]);
//     } else if (event.ctrlKey && !isFileSelected) {
//       // If Ctrl/Meta key is pressed, add this file to the selection
//       handleMultiSelect(file);
//     }

//     // Determine context menu options based on the current selection size
//     let options;
//     if (
//       multipleFileSelect.length === 1 &&
//       isFileSelected // Single file already selected
//     ) {
//       options = ["Email", "Rename", "Download", "Fax"]; // For a single file
//     } else if (
//       multipleFileSelect.length > 1 ||
//       (multipleFileSelect.length === 0 && !isFileSelected)
//     ) {
//       // Multiple files selected or if no files were selected before right-click
//       options = ["Email", "Download"]; // For multiple files
//     }
//     // else if (multipleFileSelect.length === 0 && isFileSelected) {
//     //   // Special case: right-click on an unselected file while having no selection
//     //   options = ["Email", "Rename", "Download", "Fax"];
//     // }

//     // Display the context menu at the position of the right-click
//     setContextMenu({
//       visible: true,
//       x: event.clientX,
//       y: event.clientY,
//       options,
//     });
//   };

//   console.log(multipleFileSelect);

//   //  Function to handle closing the context menu
//   const handleContextMenuClose = () => {
//     setContextMenu({ visible: false });
//   };

//   const handleContextMenuOptionClick = (option, selectedFiles) => {
//     handleContextMenuClose(); // Close the context menu

//     // Perform action based on the clicked option
//     switch (option) {
//       case "Email":
//         console.log(`Email}`);
//         console.log(selectedFiles.map((item) => item.name));

//         setMultipleFileSelect([]);

//         break;
//       case "Rename":
//         break;
//       case "Download":
//         break;

//       default:
//         break;
//     }
//   };

//   return (
//     <>
//       {messageContextHolder}
//       {userDetail && (
//         <Header
//           email={userDetail.email}
//           onLogout={handleLogout}
//           name={userDetail.name}
//           photoURL={userDetail.profilePicture}
//         />
//       )}

//       <div className="home-screen">
//         <Sidebar onUploadClick={handelUploadClick} />
//         <div className="main">
//           <main>
//             <div className="row">
//               <button
//                 className="select-all-btn"
//                 onClick={handleSelectAllButtonClick}
//               >
//                 Select All
//               </button>
//               <select
//                 className="dropdown"
//                 value={dropdownValue}
//                 onChange={handleDropdownChange}
//               >
//                 <option value="Select">Select</option>
//                 <option value="Email">Email</option>
//                 <option value="Fax">Fax</option>
//                 <option value="Download">Download</option>
//                 <option value="Rename">Rename</option>
//               </select>
//               {isUploadOverlayOpen && (
//                 <UploadOverlay
//                   onClose={closeUploadOverLay}
//                   isOpen={isUploadOverlayOpen}
//                 />
//               )}

//               {isEmailOverlayOpen && (
//                 <EmailOverlay
//                   onClose={closeEmailOverLay}
//                   selectedFilesToEmail={selectedFilesToEmail}
//                   isOpen={isEmailOverlayOpen}
//                 />
//               )}
//             </div>
//           </main>
//           <div className="container-row">
//             <div className="container1">
//               <table className="folder-table">
//                 <tbody>
//                   <tr>
//                     <td>
//                       {folderTree && (
//                         <FolderTree
//                           data={folderTree}
//                           onFileClick={handleFileSelectFromTree}
//                           onFolderSelect={handleFolderSelect} // Pass the folder select handler
//                         />
//                       )}
//                     </td>
//                   </tr>
//                 </tbody>
//               </table>
//             </div>
//             <div className="container2">
//               {errorMessage && (
//                 <div className="error-message">{errorMessage}</div>
//               )}
//               <table className="main-table">
//                 <thead>
//                   <tr>
//                     <th className="checkbox-th">
//                       <input
//                         type="checkbox"
//                         className="table-checkbox"
//                         onChange={handleSelectAll}
//                         checked={selectAll}
//                       />
//                     </th>
//                     <th className="name">Name</th>
//                     <th className="created">Created Date</th>
//                     <th className="size">Size</th>
//                     <th className="received">Received Date</th>
//                   </tr>
//                 </thead>

//                 <tbody className="tabel-body-fontsize">
//                   {fileList.map((file, index) => (
//                     <tr
//                       key={index}
//                       className="file-table-body"
//                       style={{
//                         backgroundColor: multipleFileSelect.some(
//                           (item) => item.name === file.name
//                         )
//                           ? "#c2e7ff"
//                           : "transparent",
//                       }}
//                       onClick={(event) => handleSingleFileClick(event, file)}
//                       onContextMenu={(event) => handleRightClick(event, file)}
//                     >
//                       <td className="checkbox-body">
//                         <input
//                           type="checkbox"
//                           checked={selectedFiles[file.name] || false}
//                           onChange={() => handleFileSelectChange(file.name)}
//                         />
//                       </td>
//                       <td
//                         className="my-date"
//                         onClick={() =>
//                           handleFileClick(file.name, file.path, index, fileList)
//                         }
//                       >
//                         {file.name}
//                       </td>
//                       <td className="my-date">{file.createdDate || "N/A"}</td>
//                       <td className="my-date">{file.size || "N/A"}KB</td>
//                       <td className="my-date">{file.receivedDate || "N/A"}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table>
//               {isFilePreviewOverlayOpen && (
//                 <FilePreviewOverlay
//                   fileName={selectedFile}
//                   filePath={selectedFilePath}
//                   fileIndex={currentFileIndex}
//                   allFiles={fileList}
//                   onClose={handleFilePreviewClose}
//                 />
//               )}
//               {/* {isOverlayOpen && (
//                 <Overlay fileName={selectedFile} onClose={handelClose} />
//               )} */}

//               {/* Conditionally render the context menu */}
//               {/* {contextMenu.visible && (
//                 <ul
//                   className="context-menu"
//                   style={{
//                     position: "absolute",
//                     top: contextMenu.y,
//                     left: contextMenu.x,
//                     listStyle: "none",
//                     padding: "10px",
//                     backgroundColor: "white",
//                     boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
//                   }}
//                 >
//                   {contextMenu.options.map((option, idx) => (
//                     <li key={idx} onClick={handleContextMenuClose}>
//                       {option}
//                     </li>
//                   ))}
//                 </ul>
//               )} */}

//               {contextMenu.visible && (
//                 <ul
//                   className={`context-menu ${
//                     contextMenu.visible ? "show" : ""
//                   }`}
//                   style={{ top: contextMenu.y, left: contextMenu.x }}
//                 >
//                   {contextMenu.options.map((option, idx) => (
//                     <li
//                       key={idx}
//                       onClick={() =>
//                         handleContextMenuOptionClick(option, multipleFileSelect)
//                       }
//                     >
//                       {option}
//                     </li>
//                   ))}
//                 </ul>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

// ---------------------------------------------------
// const handleFolderClick = (folderPath) => {
//   if (folderPath === "Home") {
//     fetchFilesAndFolders();
//     setSelectedFolder("Home");
//     setCurrentFolderPath(null);

//     // Reset to initial files and root folders
//     setCurrentItems(initialItems);
//     return; // Stop further execution
//   }

//   setSelectedFolder(folderPath);
//   setCurrentFolderPath(folderPath);

//   // Get files directly inside the selected folder
//   const folderFiles = files.filter((file) => {
//     return (
//       file.name.startsWith(`${folderPath}/`) &&
//       file.name.substring(folderPath.length + 1).split("/").length === 1
//     );
//   });

//   const folderParts = folderPath.split("/");
//   let currentFolderTree = folderTree;

//   folderParts.forEach((part) => {
//     if (currentFolderTree[part]) {
//       currentFolderTree = currentFolderTree[part];
//     }
//   });

//   const subfolders = Object.keys(currentFolderTree);

//   const folderFolders = subfolders.map((subfolder) => ({
//     name: `${folderPath}/${subfolder}`,
//     isFolder: true,
//     path: `${folderPath}/${subfolder}`,
//   }));

//   const newCurrentItems = [
//     ...folderFiles.map((file) => ({
//       name: file.name,
//       isFolder: false,
//       path: file.name,
//       createdDate: file.createdDate,
//       size: file.size,
//     })),
//     ...folderFolders,
//   ];

//   setCurrentItems(newCurrentItems);
// };
