import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import googleImg from "../assets/google-logo.png";
import "../components/SignInWithGoogle.css";
import { auth, db } from "./firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc, setDoc } from "firebase/firestore";

// export default function SignInWithGoogle() {
//   const navigate = useNavigate();
//   async function googleLogin() {
//     const provider = new GoogleAuthProvider();
//     try {
//       const result = await signInWithPopup(auth, provider);
//       const user = result.user;

//       if (user) {
//         // Storing user data into firestore

//         await setDoc(doc(db, "Users", user.uid), {
//           email: user.email,
//           name: user.displayName,
//           lastLogin: new Date(),
//           profilePicture: user.photoURL,
//         });

//         navigate("/home");
//       }
//     } catch (error) {
//       console.error("Error during Google login:", error);
//     }
//   }

//   return (
//     <div className="google-login-container">
//       <p className="or-text">---or continue with---</p>
//       <button className="google-login-button" onClick={googleLogin}>
//         <img src={googleImg} alt="Google Logo" className="google-logo" />
//         <span className="google-login-text">Sign in with Google</span>
//       </button>
//     </div>
//   );
// }

export default function SignInWithGoogle() {
  const navigate = useNavigate();

  async function googleLogin() {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          // User data exists
          navigate("/home", { state: { userDetail: docSnap.data() } });
        } else {
          // If no user data, create a new document
          await setDoc(docRef, {
            email: user.email,
            name: user.displayName,
            lastLogin: new Date(),
            profilePicture: user.photoURL,
          });
          navigate("/home", {
            state: {
              userDetail: {
                email: user.email,
                name: user.displayName,
                profilePicture: user.photoURL,
              },
            },
          });
        }
      }
    } catch (error) {
      console.error("Error during Google login:", error);
    }
  }

  return (
    <div className="google-login-container">
      <p className="or-text">---or continue with---</p>
      <button className="google-login-button" onClick={googleLogin}>
        <img src={googleImg} alt="Google Logo" className="google-logo" />
        <span className="google-login-text">Sign in with Google</span>
      </button>
    </div>
  );
}
