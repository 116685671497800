export const EMAIL_API_HOST_URL = process.env.REACT_APP_EMAIL_API_URL;

export default async function EmailService(emailData) {
  let files = emailData.selectedFiles
    .map((file, index) => `${index + 1}) ${file.name}`)
    .join("<br>");

  let fileLocations = emailData.selectedFiles
    .map((file) => file.path.startsWith('/') ? file.path.slice(1) : file.path);

  // API POST CALL
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const emailPayload = JSON.stringify({
    name: emailData.emailName,
    to: emailData.emailName.split(","),
    subject: emailData.subject,
    body: `${emailData.body}<br/><br/> ${emailData.selectedFiles.length} file(s) attached:<br\>${files}`,
    fileLocations: fileLocations,
  });

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: emailPayload,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      `${EMAIL_API_HOST_URL}/6f633b9a-184a-4fbf-bff8-0801ddc0279e`,
      requestOptions
    );
    if (response.ok) {
      const result = await response.text();
      //console.log(result);
      return true;
    } else {
      //console.error('Fetch failed with status:', response.status);
      return false;
    }
  } catch (error) {
    //console.error('Fetch error:', error);
    return false;
  }
}
