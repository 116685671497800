import React from "react";
import { useState, useEffect } from "react";
import {
  AiOutlineClose,
  AiFillCaretLeft,
  AiFillCaretRight,
} from "react-icons/ai";
import "./FilePreviewOverlay.css";
import FilePreview from "./FilePreview";

export default function FilePreviewOverlay({
  fileName,
  fileIndex,
  allFiles,
  onClose,
  onFullScreen,
}) {
  const [currentFile, setCurrentFile] = useState(null);
  const [currentFilePath, setCurrentFilePath] = useState();
  const [currentFileIndex, setCurrentFileIndex] = useState(fileIndex);
  const [fileUpdated, setFileUdpated] = useState(false);
  const [disablePrev, setDisablePrev] = useState(
    fileIndex === 0 ? true : false
  );
  const [disableNext, setDisableNext] = useState(
    fileIndex === allFiles.length - 1 ? true : false
  );

  const handleArrow = (i) => {
    setFileUdpated(false);
    setCurrentFileIndex((indx) => indx + i);
    console.log("CurrentIndex:", currentFileIndex);
  };

  useEffect(() => {
    currentFileIndex === 0 ? setDisablePrev(true) : setDisablePrev(false);
    currentFileIndex === allFiles.length - 1
      ? setDisableNext(true)
      : setDisableNext(false);
    const name = allFiles[currentFileIndex];
    console.log("CurrentFileName2", name.path);
    setCurrentFile(name.name);
    setCurrentFilePath(name.path);
    setFileUdpated(true);
  }, [currentFileIndex]);

  return (
    <div className={`overlay ${fileName ? "active" : ""}`}>
      <div className="overlay-content">
        {/* Header */}
        <div className="HeaderContainer">
          <div className="HeaderTitle">{currentFile}</div>
          <div className="HeaderClose">
            <AiOutlineClose className="close-btn" onClick={onClose} />
          </div>
        </div>
        {/* Content */}
        <div className="preview">
          <div className="leftArrow-icon-container">
            {!disablePrev && (
              <AiFillCaretLeft
                className="leftArrow"
                onClick={() => handleArrow(-1)}
              />
            )}
          </div>
          <div className="previewContent custom-scrollbar">
            {fileUpdated && <FilePreview FilePath={currentFilePath} />}
          </div>
          <div className="rightArrow-icon-container">
            {!disableNext && (
              <AiFillCaretRight
                className="rightArrow"
                onClick={() => handleArrow(1)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
