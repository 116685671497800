import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../components/UserProvider";
import { useEffect, useState } from "react";
import { auth } from "../components/firebase";
import Header from "../components/Header";
import Sidebar from "../components/SideBar";

import "../screens/FaxScreen.css";

export default function FaxScreen() {
  const { userDetail, loading, error } = useUser();
  const navigate = useNavigate();

  const location = useLocation();
  const [selectedFiles, setSelectedFiles] = useState(
    location.state?.selectedFiles || []
  );

  // State for fax form
  const [faxNumber, setFaxNumber] = useState("");
  const [description, setDescription] = useState("");

  // Error states
  const [faxNumberError, setFaxNumberError] = useState("");

  useEffect(() => {
    if (loading) {
      console.log("Loading state: ", loading);
      return;
    }
    if (!userDetail) {
      console.error("No user is signed in.");
      navigate("/");
    }
  }, [loading, userDetail, navigate]);

  useEffect(() => {
    if (error) {
      console.error("Error state: ", error);
    }
  }, [error]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userDetail) {
    return <div>Error: No user is signed in.</div>;
  }

  //  Handel logout button function
  function handleLogout() {
    auth
      .signOut()
      .then(() => {
        console.log("User logged out");
        navigate("/");
      })
      .catch((err) => {
        console.log("Error logging out:", err);
      });
  }

  //  Validate fax number
  const validateFaxNumber = (number) => {
    const faxNumberRegex = /^[0-9]+$/;
    return faxNumberRegex.test(number);
  };

  //  Handel Fax Submission

  const handelSubmit = async (e) => {
    e.preventDefault();

    //  reset error message
    setFaxNumberError("");

    // Validate fax number

    if (!validateFaxNumber(faxNumber)) {
      setFaxNumber("please enter valid fax number.");
      return;
    }

    const faxData = {
      faxNumber,
      description,
    };

    try {
      console.log("Sending Fax: ", faxData);

      // Simulate success response
      await new Promise((resolve) => setTimeout(resolve, 1000));

      alert("Fax sent successfully!");
      setFaxNumber("");
      setDescription("");
    } catch (err) {
      console.log("Error sending fax:", err);
      alert("Error sending fax, please try again later.");
    }
  };

  // Function to remove a file
  const handleDeleteFile = (id) => {
    const updatedFiles = selectedFiles.filter((file) => file.id !== id);
    setSelectedFiles(updatedFiles);
  };

  return (
    <>
      {userDetail && (
        <Header
          email={userDetail.email}
          onLogout={handleLogout}
          photoURL={userDetail.profilePicture}
          name={userDetail.name}
        />
      )}
      <div className="home-body">
        <Sidebar />
        <div className="fax-container">
          <p className="fax-title">Fax</p>
          <div className="input-group">
            <div className="input-wrapper">
              <input
                type="text"
                placeholder="Fax Number"
                className="fax-input"
                value={faxNumber}
                onChange={(e) => setFaxNumber(e.target.value)}
                required
              />
              {faxNumberError && (
                <p className="error-message">{faxNumberError}</p>
              )}
            </div>
          </div>
          <div className="input-group">
            <div className="input-wrapper">
              <textarea
                placeholder="Description"
                className="description-input"
                value={description}
                maxLength={250}
                onChange={(e) => setDescription(e.target.value)}
                required
              />
            </div>
          </div>
          <button
            type="submit"
            className="proceed-button"
            onClick={handelSubmit}
          >
            Send
          </button>

          {selectedFiles.length > 0 ? (
            <div className="file-table">
              <table>
                <thead>
                  <tr>
                    <th>Delete</th>
                    <th>File Name</th>
                    <th>Content-Type</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedFiles.map((file) => (
                    <tr key={file.id}>
                      <td>
                        <button
                          className="delete-button"
                          onClick={() => handleDeleteFile(file.id)}
                        >
                          X
                        </button>
                      </td>
                      <td>{file.name}</td>
                      <td>{file.type}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="file-message">
              No files uploded. please add files to proceed.
            </p>
          )}
        </div>
      </div>
    </>
  );
}
