import { useEffect } from "react";
import { useUser } from "../components/UserProvider";
import { useNavigate } from "react-router-dom";
import { auth } from "../components/firebase";
import Header from "../components/Header";

export default function Attorney() {
  const { userDetail, loading, error } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      console.log("Loading state: ", loading);
      return;
    }
    if (!userDetail) {
      console.error("No user is signed in.");
      navigate("/");
    }
  }, [loading, userDetail, navigate]);

  useEffect(() => {
    if (error) {
      console.error("Error state: ", error);
    }
  }, [error]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!userDetail) {
    return <div>Error: No user is signed in.</div>;
  }

  function handleLogout() {
    auth
      .signOut()
      .then(() => {
        console.log("User logged out");
        navigate("/");
      })
      .catch((err) => {
        console.log("Error logging out:", err);
      });
  }

  return (
    <>
      {userDetail && (
        <Header
          email={userDetail.email}
          onLogout={handleLogout}
          photoURL={userDetail.profilePicture}
          name={userDetail.name}
        />
      )}
    </>
  );
}
