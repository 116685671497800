import { AiOutlineDownload } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import FileViewer from "react-file-viewer";
const READ_API_HOST_URL = process.env.REACT_APP_READ_API_URL;

// function FilePreview({ FilePath }) {
//     const [isFileSupported, setIsFileSupported] = useState(true);

//     console.log("FilePreview", FilePath);
//     const removePrefix = (filePath) => {
//         return filePath.startsWith('/') ? filePath.slice(1) : filePath;
//     }

//     const getFileExtension = (filePath) => {
//         return filePath.split('.').pop();
//     };

//     const filePath = `${READ_API_HOST_URL}/getFileContent?name=${removePrefix(FilePath)}&kk=${Math.random()}`;

//     const handleUnsupportedFile = () => {
//         setIsFileSupported(false);
//     };

//     const type = getFileExtension(FilePath);
//     return (
//         <>
//             {isFileSupported &&
//                 <FileViewer
//                     fileType={type}
//                     filePath={filePath}
//                     style={{ height: '100%' }}
//                     onError={handleUnsupportedFile}
//                 />}
//             {!isFileSupported &&
//                 <>
//                     <div className="unSupportedFile HeaderTitle">
//                         <div>
//                             Preview not available for this file.&nbsp;<br />
//                             Please download this file.&nbsp;<br />
//                         </div>
//                         <div>
//                             <a href={filePath} className="downloadFileIcon"><AiOutlineDownload className="downloadFileIcon" /></a>
//                         </div>
//                     </div>
//                 </>}
//         </>
//     );

// }

// export default FilePreview;

function FilePreview({ FilePath }) {
  const [isFileSupported, setIsFileSupported] = useState(true);

  // Debugging statement to log the FilePath
  console.log("FilePreview FilePath:", FilePath);

  const removePrefix = (filePath) => {
    return filePath.startsWith("/") ? filePath.slice(1) : filePath;
  };

  // Check if FilePath is valid before proceeding
  if (!FilePath) {
    console.error("FilePath is undefined");
    return <div>Error: File path is not defined.</div>;
  }

  const getFileExtension = (filePath) => {
    return filePath.split(".").pop();
  };

  const filePath = `${READ_API_HOST_URL}/getFileContent?name=${removePrefix(
    FilePath
  )}&kk=${Math.random()}`;

  const handleUnsupportedFile = () => {
    setIsFileSupported(false);
  };

  const type = getFileExtension(FilePath);
  return (
    <>
      {isFileSupported && (
        <FileViewer
          fileType={type}
          filePath={filePath}
          style={{ height: "100%" }}
          onError={handleUnsupportedFile}
        />
      )}
      {!isFileSupported && (
        <>
          <div className="unSupportedFile HeaderTitle">
            <div>
              Preview not available for this file.&nbsp;
              <br />
              Please download this file.&nbsp;
              <br />
            </div>
            <div>
              <a href={filePath} className="downloadFileIcon">
                <AiOutlineDownload className="downloadFileIcon" />
              </a>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default FilePreview;
