import React, { useState } from "react";

import "../newComponent/SideBar.css";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ArrowRightSharpIcon from "@mui/icons-material/ArrowRightSharp";
import FolderIcon from "@mui/icons-material/Folder";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import UploadOverlay from "../components/UploadOverlay";

function Folder({
  folderName,
  folderPath,
  children,
  onClickFolder,
  selectedFolder,
  level = 0, // Indentation level
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleFolder = () => {
    setIsOpen(!isOpen);
    if (onClickFolder) {
      onClickFolder(folderPath);
    }
  };

  // Determine if the folder name is long (more than 6 characters)
  const isLongName = folderName.length > 6;

  // Truncate the folder name if it's long
  const displayName = isLongName ? `${folderName.slice(0, 6)}...` : folderName;

  return (
    <div className="folder">
      <div
        className={`sidebar1-option folder-header ${
          selectedFolder === folderPath ? "selected" : ""
        }`}
        onClick={toggleFolder}
      >
        {/* Group the arrow, folder icon, and folder name together */}
        <span
          style={{
            paddingLeft: `${level * 15}px`,
            display: "flex",
            alignItems: "center",
            gap: "5px", // Adjust this value if you need more or less spacing between the icon and name
          }}
        >
          {/* Arrow and Folder icons together */}
          {isOpen ? <ArrowDropDownSharpIcon /> : <ArrowRightSharpIcon />}
          {isOpen ? <FolderOpenIcon /> : <FolderIcon />}
          {/* Folder name next to the icons */}
          <span
            className={`folder-name ${isLongName ? "long-name" : ""}`}
            title={isLongName ? folderName : ""} // Tooltip for long names
          >
            {displayName}
          </span>
        </span>
      </div>

      {isOpen && children && (
        <div className="folder-children">
          {Object.keys(children).map((childFolder) => (
            <Folder
              key={childFolder}
              folderName={childFolder}
              folderPath={`${folderPath}/${childFolder}`}
              children={children[childFolder]}
              onClickFolder={onClickFolder}
              selectedFolder={selectedFolder}
              level={level + 1} // Indentation for child folders
            />
          ))}
        </div>
      )}
    </div>
  );
}

function SideBar1({
  folderTree,
  onFolderClick,
  selectedFolder,
  onUploadButtonClick,
}) {
  return (
    <div className="sidebar1">
      <div className="sidebar1-btn">
        <button onClick={onUploadButtonClick}>
          <CloudUploadOutlinedIcon />
          <span>Upload</span>
        </button>
      </div>
      <hr className="hr" />

      <div className="sidebar1-options">
        <div className="sidebar1-option history">
          <HistoryOutlinedIcon />
          <span>History</span>
        </div>

        <div className="sidebar1-option recent-file">
          <FileOpenOutlinedIcon />
          <span>Recent Files</span>
        </div>

        <hr style={{ marginBottom: "10px" }} />

        <div
          className={`sidebar1-option ${
            selectedFolder === "Home" ? "selected" : ""
          }`} // Apply selected class for Home folder
          onClick={() => onFolderClick("Home")} // Click Home folder
        >
          <FolderIcon />
          <span className="sidebar1-home">Home</span>
        </div>

        <div className="folder-tree1">
          {Object.keys(folderTree).length > 0 ? (
            Object.keys(folderTree).map((folderName) => (
              <Folder
                key={folderName}
                folderName={folderName}
                folderPath={folderName} // Set folder path as its name for root folders
                children={folderTree[folderName]}
                onClickFolder={onFolderClick} // Click handler to select folder
                selectedFolder={selectedFolder} // Pass down the selected folder path
              />
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SideBar1;

//   REFERANCE CODE

// function Folder({
//   folderName,
//   folderPath,
//   children,
//   onClickFolder,
//   selectedFolder,
//   level = 0, // Indentation level
// }) {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleFolder = () => {
//     setIsOpen(!isOpen);
//     if (onClickFolder) {
//       onClickFolder(folderPath);
//     }
//   };

//   // Determine if the folder name is long (more than 6 characters)
//   const isLongName = folderName.length > 6;

//   return (
//     <div className="folder">
//       <div
//         className={`sidebar1-option folder-header ${
//           selectedFolder === folderPath ? "selected" : ""
//         }`}
//         onClick={toggleFolder}
//       >
//         {/* Group the arrow, folder icon, and folder name together */}
//         <span
//           style={{
//             paddingLeft: `${level * 15}px`,
//             display: "flex",
//             alignItems: "center",
//             gap: "5px", // Adjust this value if you need more or less spacing between the icon and name
//           }}
//         >
//           {/* Arrow and Folder icons together */}
//           {isOpen ? <ArrowDropDownSharpIcon /> : <ArrowRightSharpIcon />}
//           {isOpen ? <FolderOpenIcon /> : <FolderIcon />}
//           {/* Folder name next to the icons */}
//           <span
//             className={`folder-name ${isLongName ? "long-name" : ""}`}
//             title={isLongName ? folderName : ""}
//           >
//             {folderName}
//           </span>
//         </span>
//       </div>

//       {isOpen && children && (
//         <div className="folder-children">
//           {Object.keys(children).map((childFolder) => (
//             <Folder
//               key={childFolder}
//               folderName={childFolder}
//               folderPath={`${folderPath}/${childFolder}`}
//               children={children[childFolder]}
//               onClickFolder={onClickFolder}
//               selectedFolder={selectedFolder}
//               level={level + 1} // Indentation for child folders
//             />
//           ))}
//         </div>
//       )}
//     </div>
//   );
// }
