// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB4Fif0W7gHfYkF88NiFeu5H-hGoLnTbVQ",
  authDomain: "ezbyte-admin-panel.firebaseapp.com",
  projectId: "ezbyte-admin-panel",
  storageBucket: "ezbyte-admin-panel.appspot.com",
  messagingSenderId: "759026620490",
  appId: "1:759026620490:web:079818be12858050672eb3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth();

// getAuth is responsible for storing the users data

export { auth };
export const db = getFirestore(app);
export default app;
