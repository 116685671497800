// import {
//   Navigate,
//   Route,
//   BrowserRouter as Router,
//   Routes,
// } from "react-router-dom";
// import { ToastContainer } from "react-toastify";

// import LoginScreen from "./screens/LoginScreen";
// import RegisterScreen from "./screens/RegisterScreen";
// import HomeScreen from "./screens/HomeScreen";
// import { useEffect, useState } from "react";
// import { auth } from "./components/firebase";
// import UploadScreen from "./screens/UploadScreen";
// import { UserProvider } from "./components/UserProvider";
// import Attorney from "./screens/Attorney";
// import EmailScreen from "./screens/EmailScreen";
// import FaxScreen from "./screens/FaxScreen";

// function App() {
//   // const [user, setUser] = useState();
//   const [user, setUser] = useState(null);
//   // eslint-disable-next-line no-unused-vars
//   const [loading, setLoading] = useState(true);

//   // useEffect(() => {
//   //   auth.onAuthStateChanged((user) => {
//   //     setUser(user);
//   //   });
//   // });

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       if (user) {
//         setUser(user); // or fetch user details from Firestore if needed
//       } else {
//         setUser(null);
//       }
//       setLoading(false);
//     });

//     return () => unsubscribe();
//   }, []);

//   return (
//     <UserProvider>
//       <Router>
//         <Routes>
//           <Route
//             path="/"
//             element={user ? <Navigate to="/home" /> : <LoginScreen />}
//           />
//           <Route path="/login" element={<LoginScreen />} />
//           <Route path="/register" element={<RegisterScreen />} />
//           <Route path="/home" element={<HomeScreen />} />

//           <Route path="/upload" element={<UploadScreen />} />
//           <Route path="/attorney" element={<Attorney />} />
//           <Route path="email" element={<EmailScreen />} />
//           <Route path="fax" element={<FaxScreen />} />
//         </Routes>
//         <ToastContainer />
//       </Router>
//     </UserProvider>
//   );
// }

// export default App;

import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { auth } from "./components/firebase";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import HomeScreen from "./screens/HomeScreen";
import UploadScreen from "./screens/UploadScreen";
import Attorney from "./screens/Attorney";
import EmailScreen from "./screens/EmailScreen";
import FaxScreen from "./screens/FaxScreen";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RingLoader } from "react-spinners";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user); // User is authenticated
      } else {
        setUser(null); // No user, so not authenticated
      }
      setLoading(false); // Finished loading auth state
    });

    return () => unsubscribe(); // Clean up subscription on unmount
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" />
      </div>
    );
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to="/home" /> : <LoginScreen />}
        />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route
          path="/home"
          element={user ? <HomeScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/upload"
          element={user ? <UploadScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/attorney"
          element={user ? <Attorney /> : <Navigate to="/" />}
        />
        <Route
          path="/email"
          element={user ? <EmailScreen /> : <Navigate to="/" />}
        />
        <Route
          path="/fax"
          element={user ? <FaxScreen /> : <Navigate to="/" />}
        />
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
