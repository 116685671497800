import UploadScreen from "../screens/UploadScreen";
import "../components/UploadOverlay.css";
import { useEffect, useState } from "react";
import { json, useNavigate } from "react-router-dom";
import { RingLoader } from "react-spinners";
import { FaX } from "react-icons/fa6";

import { ExclamationCircleOutlined } from "@ant-design/icons";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import CloseIcon from "@mui/icons-material/Close";
import { auth, db } from "./firebase";
import { doc, getDoc } from "firebase/firestore";

import ErrorPopup from "../newComponent/ErrorPopUp";
import { message } from "antd";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import warning from "antd/es/_util/warning";

import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

export const UPLOAD_API_URL = process.env.REACT_APP_UPLOAD_API_URL;

export default function UploadOverlay({ onClose, isOpen, folderPath }) {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingName, setEditingName] = useState("");
  const [userDetail, setUserDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filesLoading, setFileLoading] = useState(false);
  const [showErrorPopup, setShowErrorPopup] = useState(false); // State for showing the error popup

  const [messageApi, messageContextHolder] = message.useMessage();

  console.log(
    `PRINTING THE CURRENT FOLDER PATH WHILE ENTERING UPLOAD : |${folderPath}|`
  );

  useEffect(() => {
    if (isOpen) {
      setFiles([]);
      setError(null);
      setFileLoading(false);
      setShowErrorPopup(false); // Ensure popup is closed when opening overlay
    }
  }, [isOpen]);

  useEffect(() => {
    const fetchUserDetail = async () => {
      try {
        const user = auth.currentUser;
        if (user) {
          const docRef = doc(db, "Users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            setUserDetail(docSnap.data());
          } else {
            setError("User data not found.");
          }
        } else {
          setError("No user is signed in.");
        }
      } catch (err) {
        setError("Failed to fetch user data.");
        console.error("Error fetching user data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetail();
  }, []);

  if (loading) {
    return (
      <div className="spinner-container">
        <RingLoader color="#3498db" />
      </div>
    );
  }

  if (!userDetail) {
    return <div>No user details available</div>;
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    addFiles(newFiles);
  };

  const handleFileSelect = (e) => {
    const newFiles = Array.from(e.target.files);
    addFiles(newFiles);
  };

  const addFiles = (newFiles) => {
    const totalFiles = files.length + newFiles.length;

    if (totalFiles > 4) {
      messageApi.open({
        type: "warning",
        icon: (
          <ErrorOutlineOutlinedIcon
            style={{
              backgroundColor: "white",
              paddingRight: "2px",
              color: "#faad14",
            }}
          />
        ),
        content: (
          <div
            style={{
              margin: 0,
              backgroundColor: "white",
              color: "#faad14", // Change text color
            }}
          >
            Only Select up-to 4 Files.
          </div>
        ),
        duration: 3,
        style: {
          margin: 0,
        },
      });

      return;
    }

    const updatedFiles = [
      ...files,
      ...newFiles.map((file) => ({
        file,
        name: file.name,
        size: file.size,
        category: "",
        path: folderPath,
      })),
    ];
    setFiles(updatedFiles);
    console.log(updatedFiles);
  };

  const handleDelete = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleEditFileName = (index, newName) => {
    const updatedFiles = [...files];
    const fileExtension = getFileExtension(updatedFiles[index].name);
    updatedFiles[index].name = `${newName}.${fileExtension}`;
    setFiles(updatedFiles);
    setEditingIndex(null); // Exit edit mode
  };

  const preventDefault = (e) => e.preventDefault();

  const getFileNameWithoutExtension = (fileName) => {
    return fileName.split(".").slice(0, -1).join(".");
  };

  const getFileExtension = (fileName) => {
    return fileName.split(".").pop();
  };

  console.log(
    "Current Folder Path before submission In UploadPage:",
    folderPath
  );

  const handleSubmit = async () => {
    setFileLoading(true); // Start the loading state
    setError(null); // Clear any previous errors

    const formData = new FormData();

    let filesList = [];

    files.forEach((fileWrapper) => {
      let myCheck = "";

      if (folderPath !== null) {
        myCheck = folderPath;
      }

      const uploadInfo = {
        files: fileWrapper.file,
        name: fileWrapper.name,
        category: "3300",
        userid: userDetail.email,
        path: myCheck,
      };
      filesList.push(uploadInfo);

      console.log(fileWrapper); // Log to check fileWrapper contents
      formData.append("files", fileWrapper.file);
      formData.append("category", fileWrapper.category || "3300");
      formData.append("name", fileWrapper.name || "defaultName");
      formData.append("userid", userDetail.email || "defaultUser");

      console.log(`MY CHECK XXX :  |${myCheck}|`);
      formData.append("path", myCheck);

      formData.append("path", myCheck);
    });
    console.log(`ALL FILES LIST : ${JSON.stringify(filesList)}`);

    // Debugging: Log FormData to see what is being sent
    for (let pair of formData.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }

    console.log("--------------------------------");
    console.log(formData);
    try {
      const response = await fetch(`${UPLOAD_API_URL}/multi/details`, {
        method: "POST",
        body: formData,
        headers: {
          "Cache-Control": "no-cache",
        },
      });

      const data = await response.json();

      console.log("Response data:", data); // Log response data
      if (response.ok) {
        setFiles([]); // Clear files on successful upload
        setError(null); // Clear error state
        onClose();
      } else {
        console.error("Upload failed:", data);
        setError("Upload failed: " + data.message); // Set error message
        setShowErrorPopup(true); // Show error popup
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setError("Error uploading files."); // Display upload error
      setShowErrorPopup(true); // Show error popup
    } finally {
      setFileLoading(false); // End the loading state
    }
  };

  // const handleSubmit = async () => {
  //   setFileLoading(true); // Start the loading state
  //   setError(null); // Clear any previous errors
  //   console.log("Current Folder Path a submission:", folderPath);
  //   const formData = new FormData();

  //   files.forEach((fileWrapper) => {
  //     console.log(fileWrapper); // Log to check fileWrapper contents
  //     formData.append("files", fileWrapper.file);
  //     formData.append("category", fileWrapper.category || "3300");
  //     formData.append("name", fileWrapper.name || "defaultName");
  //     formData.append("userid", userDetail.email || "defaultUser");

  //     // const pathToUse = folderPath === "" ? "" : folderPath;

  //     formData.append("path", folderPath);
  //   });

  //   // Debugging: Log FormData to see what is being sent
  //   for (let pair of formData.entries()) {
  //     console.log(pair[0] + ": " + pair[1]);
  //   }

  //   try {
  //     const response = await fetch(`${UPLOAD_API_URL}/multi/details`, {
  //       method: "POST",
  //       body: formData,
  //       headers: {
  //         "Cache-Control": "no-cache",
  //       },
  //     });

  //     const data = await response.json();

  //     console.log("Response data:", data); // Log response data
  //     if (response.ok) {
  //       setFiles([]); // Clear files on successful upload
  //       setError(null); // Clear error state
  //       onClose();
  //     } else {
  //       console.error("Upload failed:", data);
  //       setError("Upload failed: " + data.message); // Set error message
  //       setShowErrorPopup(true); // Show error popup
  //     }
  //   } catch (error) {
  //     console.error("Error uploading files:", error);
  //     setError("Error uploading files."); // Display upload error
  //     setShowErrorPopup(true); // Show error popup
  //   } finally {
  //     setFileLoading(false); // End the loading state
  //   }
  // };

  const formatFileSize = (size) => {
    if (size < 1024) return size + " bytes";
    else if (size < 1048576) return (size / 1024).toFixed(2) + " KB";
    else return (size / 1048576).toFixed(2) + " MB";
  };

  // Return null if the overlay is not open
  if (!isOpen) return null;

  return (
    <>
      {messageContextHolder}
      {showErrorPopup && (
        <ErrorPopup
          message={error}
          onClose={() => {
            setShowErrorPopup(false);
            setError(null); // Reset error state when closing
          }}
        />
      )}
      <div className="upload-overlay">
        <div className="upload-overlay-content">
          <div className="content-top">
            <div className="content-top-name">
              <span>Upload</span>
            </div>
            <div className="content-top-close" onClick={onClose}>
              <CloseIcon />
            </div>
          </div>
          <div className="upload-section">
            <div
              className="drop-zone"
              onDrop={handleDrop}
              onDragOver={preventDefault}
              onDragEnter={preventDefault}
            >
              <span>Drag and Drop or Browse & Select File(s)</span>
            </div>
            <div className="drop-zone-select">
              <button
                className="button select-files-button"
                onClick={() => document.getElementById("file-input").click()}
              >
                Select Files
              </button>
            </div>
          </div>

          {filesLoading && (
            <div className="loader-container">
              <RingLoader color="#3498db" />
            </div>
          )}

          <input
            id="file-input"
            type="file"
            multiple
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
          {files.length > 0 ? (
            <div className="tab-container">
              <table>
                <thead>
                  <tr>
                    <th>
                      <CloseOutlinedIcon />
                    </th>
                    <th>File Name</th>
                    <th>File Size</th>
                  </tr>
                </thead>
                {/* <tbody>
                  {files.map((fileWrapper, index) => (
                    <tr key={index}>
                      <td>
                        <CloseOutlinedIcon
                          onClick={() => handleDelete(index)}
                        />
                      </td>
                      <td>
                        {editingIndex === index ? (
                          <input
                            type="text"
                            value={editingName}
                            onChange={(e) => setEditingName(e.target.value)}
                            onBlur={() =>
                              handleEditFileName(index, editingName)
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleEditFileName(index, editingName);
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          <span
                            onClick={() => {
                              setEditingIndex(index);
                              setEditingName(
                                getFileNameWithoutExtension(fileWrapper.name)
                              );
                            }}
                          >
                            {fileWrapper.name}
                            <EditOutlinedIcon style={{ width: "16px" }} />
                          </span>
                        )}
                      </td>

                      <td>
                        <span>{formatFileSize(fileWrapper.size)}</span>
                      </td>
                    </tr>
                  ))}
                </tbody> */}

                <tbody className="table-body">
                  {files.map((fileWrapper, index) => (
                    <tr key={index}>
                      <td style={{ textAlign: "center" }}>
                        <CloseOutlinedIcon
                          className="close"
                          onClick={() => handleDelete(index)}
                        />
                      </td>
                      <td>
                        {editingIndex === index ? (
                          <input
                            type="text"
                            value={editingName}
                            onChange={(e) => setEditingName(e.target.value)}
                            onBlur={() =>
                              handleEditFileName(index, editingName)
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                handleEditFileName(index, editingName);
                              }
                            }}
                            autoFocus
                          />
                        ) : (
                          <div
                            className="file-name-wrapper"
                            onClick={() => {
                              setEditingIndex(index);
                              setEditingName(
                                getFileNameWithoutExtension(fileWrapper.name)
                              );
                            }}
                          >
                            <span className="file-name">
                              {fileWrapper.name}
                            </span>
                            <EditOutlinedIcon
                              style={{ width: "16px", fill: "#008631" }}
                              className="edit-icon"
                            />
                          </div>
                        )}
                      </td>
                      <td style={{ textAlign: "left" }}>
                        <span>{formatFileSize(fileWrapper.size)}</span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="message">No files selected.</div>
          )}
          <div className="submit-button-container">
            {files.length > 0 && files.length <= 4 ? (
              <button
                className="submit-button"
                disabled={filesLoading} // Disable only during file upload
                onClick={handleSubmit}
              >
                Submit
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}

//  OLD HANDEL SUBMIT FUNCTION

// const handleSubmit = async () => {
//   setFileLoading(true); // Start the loading state
//   setError(null); // Clear any previous errors

//   const formData = new FormData();

//   files.forEach((fileWrapper) => {
//     console.log(fileWrapper); // Log to check fileWrapper contents
//     formData.append("files", fileWrapper.file);
//     formData.append("category", fileWrapper.category || "3300");
//     formData.append("name", fileWrapper.name || "defaultName");
//     formData.append("userid", userDetail.email || "defaultUser");
//     formData.append("path", fileWrapper.path || "/");
//   });

//   // Debugging: Log FormData to see what is being sent
//   for (let pair of formData.entries()) {
//     console.log(pair[0] + ": " + pair[1]);
//   }

//   try {
//     const response = await fetch(`${UPLOAD_API_URL}/multi/details`, {
//       method: "POST",
//       body: formData,
//       headers: {
//         "Cache-Control": "no-cache",
//       },
//     });

//     const data = await response.json();
//     if (response.ok) {
//       setFiles([]); // Clear files on successful upload
//       setError(null); // Clear error state
//       console.log("Upload successful:", data);
//       onClose();
//     } else {
//       console.error("Upload failed:", data);
//       setError("Upload failed: " + data.message); // Set error message
//       setShowErrorPopup(true); // Show error popup
//     }
//   } catch (error) {
//     console.error("Error uploading files:", error);
//     setError("Error uploading files."); // Display upload error
//     setShowErrorPopup(true); // Show error popup
//   } finally {
//     setFileLoading(false); // End the loading state
//   }
// };

// const addFiles = (newFiles) => {
//   const updatedFiles = [
//     ...files,
//     ...newFiles.map((file) => ({
//       file,
//       name: file.name,
//       size: file.size,
//       category: "",
//     })),
//   ];
//   setFiles(updatedFiles);
//   console.log(updatedFiles);
// };
